<template>
  <main class="users2 padded" style="padding-bottom:0;">        
    <div class="tabs" v-if="$store.getters.isAdmin">
      <a class="textbtn" :class="{selected: usersTab}" @click="usersTab = true">Users</a> 
      <a class="textbtn" style="margin-left:20px;" :class="{selected: !usersTab}" @click="usersTab = false">Organizations</a>
    </div>
    <h2 v-else>Users</h2>
    <div>      
      <div v-show="!usersTab">
        <div class="neworg">
          <button class="left" style="height: 30px;" :disabled="!newOrg.name || loading" @click="addOrganization">Add new organization</button>   
          <input v-model.trim="newOrg.name" type="text" :disabled="loading" autocomplete="off" placeholder="Organization name" /> 
          <input v-model.trim="newOrg.emailDomain" type="text" :disabled="loading" style="margin-left: 10px;" autocomplete="off" placeholder="Email domain (wihout the @)" />          
        </div>
        <div style="color: grey; margin-top:10px; font-size: 14px;">Email domain example: "insead.edu" without the @ (this allows matching new users both from @insead.edu and any subdomains such as @it.insead.edu)</div>
        <div class="formerror" style="white-space: pre;" v-if="error">{{error}}</div>      
        <br>

        <div style="display:flex; line-height:30px; background-color: white; border-radius:5px; padding: 5px; margin-top: 0; margin-bottom: 15px; font-size:12px;">
          <!-- Year: <input type="number" v-model="report.year" style="width: 80px; margin: 0 4px;" />
          Month: <input type="number" v-model="report.month" min="1" max="12" style="width: 60px; margin: 0 4px;" /> -->
          From:
          <v-date-picker v-model="report.from" :model-config="{timeAdjust:'00:00:00'}" ref="reportFromDatePicker" :popover="{ visibility: 'focus' }" :timezone="'UTC'" style="margin: 0 4px;">    
            <template v-slot="{ /*inputValue,*/ inputEvents }">              
              <input   
                style="width: 100px; font-size: 14px;"
                ref="reportFromInput"
                name="reportFrom"
                :disabled="loading"    
                autocomplete="off"            
                type="text" 
                :value="report.from ? `${toTimeZone(report.from, 'UTC').getFullYear()}-${toTimeZone(report.from, 'UTC').getMonth()+1}-${toTimeZone(report.from, 'UTC').getDate()}` : ''"
                v-on="inputEvents"                
              />
            </template>
          </v-date-picker>
          To:
          <v-date-picker v-model="report.to" :model-config="{timeAdjust:'23:59:59'}" ref="reportToDatePicker" :popover="{ visibility: 'focus' }" :timezone="'UTC'" style="margin: 0 4px;">    
            <template v-slot="{ /*inputValue,*/ inputEvents }">              
              <input   
                style="width: 100px; font-size:14px;"
                ref="reportToInput"
                name="reportTo"
                :disabled="loading"    
                autocomplete="off"            
                type="text" 
                :value="report.to ? `${toTimeZone(report.to, 'UTC').getFullYear()}-${toTimeZone(report.to, 'UTC').getMonth()+1}-${toTimeZone(report.to, 'UTC').getDate()}` : ''"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
          BinMode: 
          <select v-model="report.binMode" :disabled="report.detailed" class="select-css" :style="`height: 30px; font-size: 14px; line-height:14px; margin: 0 4px; opacity: ${report.detailed ? 0.3 : 1}`">
            <option value="firstplay">From first ever segment played</option>
            <option value="calendar">From 00:00 on each day</option>
          </select>
          BinHours: <input type="number" :disabled="report.detailed" v-model="report.binHours" min="1" max="24" :style="`width: 60px; margin: 0 4px; opacity: ${report.detailed ? 0.3 : 1}`" />
          ReplayThresholdHours: <input type="number" :disabled="report.detailed" v-model="report.thresholdHours" min="1" max="24" :style="`width: 60px; margin: 0 4px; opacity: ${report.detailed ? 0.3 : 1}`" />          
          <input type="checkbox" v-model="report.detailed" style="height:24px;" /> Detailed          
          <button 
            class="right" 
            style="height: 30px; width: 400px; white-space:nowrap;" 
            :title="!report.from || !report.to || report.from >= report.to || $refs.orgdatatable.selectedRows.length < 1 ? 'Select organization(s) and date range' : ''"
            :disabled="loading || !report.from || !report.to || report.from >= report.to || $refs.orgdatatable.selectedRows.length < 1" 
            @click="creditReport">
              Credit report
          </button>          
        </div>

        <input v-model.trim="orgSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." style="width: 300px;" /> 
        <!-- <button class="right" style="height: 30px;" :disabled="loading" @click="creditLogs(null)">Credit logs</button>   -->
        
        <br>
        <br>
        <VueGoodTable
        ref="orgdatatable"
        :columns="orgColumns"
        :rows="organizations"        
        styleClass="vgt-table"
        :search-options="{
          enabled: false,
          externalQuery: orgSearchTerm
        }"
        max-height="calc(100lvh - var(--size-header) - 150px - 70px)"
        :fixed-header="false"
        :select-options="{ 
          enabled: true,
          disableSelectInfo: true
        }">
          
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" class="rowActions">
              <button title="Edit" class="icon" @click="editOrg(props.row.id)">
                <img src="../assets/edit.svg" />
              </button>
              <!-- <button title="Credit logs" class="icon" @click="creditLogs(props.row.id)">
                <img src="../assets/csv.svg" />
              </button> -->
            </span>          
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </VueGoodTable>
      </div>
      <div v-show="usersTab">    

        <div class="formerror" style="white-space: pre;" v-if="error">{{error}}</div>
        

        <!-- <a class="textbtn" @click="roleFilter = ''" :class="{selected: roleFilter == ''}">All users</a>
        <a class="textbtn" @click="roleFilter = Roles.Student" :class="{selected: roleFilter == 'Student'}">Students</a>
        <a class="textbtn" @click="roleFilter = Roles.Professor" :class="{selected: roleFilter == 'Professor'}">Professors</a>
        <a class="textbtn" v-if="$store.getters.isAdmin" @click="roleFilter = Roles.Admin" :class="{selected: roleFilter == 'Admin'}">Admins</a>
        <a class="textbtn" @click="roleFilter = Roles.OrgAdmin" :class="{selected: roleFilter == 'OrgAdmin'}">Organization Admins</a>
        <a class="textbtn" @click="roleFilter = 'provisional'" :class="{selected: roleFilter == 'provisional'}">Invitations</a> -->
        <!-- <span class="technicalcheckbox"><input type="checkbox" v-model="showTechnicalUsers">Show technical users</span> -->

        
        <div class="buttons"> 
          <select v-if="$store.getters.isAdmin" class="select-css" v-model="orgId" style="width: 200px;">
            <option :value=-1>No organization</option>
            <option v-for="org in organizations" v-bind:value="org.id" :key="org.id" :selected="org.id == $store.getters.organizationId">
              {{org.name}}
            </option>
          </select>
          <input v-model.trim="searchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
          <div>
            <button class="white" @click="inviteUsersModal = true;">Invite users</button>
          </div>
        </div>
     
        <VueGoodTable
        ref="datatable"
        :columns="columns"
        :rows="users"        
        styleClass="vgt-table"
        :search-options="{
          enabled: false,
          externalQuery: searchTerm
        }"
        max-height="calc(100lvh - var(--size-header) - 150px - 70px)"
        :fixed-header="false"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'email', type: 'asc'}
        }"
        :select-options="{ 
          enabled: true,
          selectOnCheckboxOnly: true 
        }">
          <template v-slot:selected-row-actions>  
            <button class="autowidth right" :disabled="loading || !selectedPreapprovedUsers.length" v-show="selectedPreapprovedUsers.length > 0" @click="removeUsers">Remove invitation</button>                  
            <button class="autowidth right" :disabled="loading || !selectedUnapprovedUsers.length" v-show="selectedUnapprovedUsers.length" @click="activateUsers">Approve</button>                      
            <button class="autowidth right" :disabled="loading || !selectedUsers.length" @click="showModal = true">Edit roles</button>                      
            <button class="autowidth right" :disabled="loading || !selectedUsers.length /*|| selectedUsers.filter(u => u.lastName == 'technical').length > 0 */ || selectedPreapprovedUsers.length > 0" @click="showUserOrgModal = true" v-if="$store.getters.isAdmin">Change organization</button> 
            <!-- <button class="insead autowidth right" :disabled="loading || !selectedUsers.length || selectedPreapprovedUsers.length > 0" @click="unassignAllSessionsBulk">Unassign Sessions</button>                           -->
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" class="rowActions">
              <!-- <button class="icon" title="Sessions of user" @click="editRow(props.row.id)" :disabled="!props.row.id">
                <img src="../assets/removesessions.svg" style="width:16px;" />
              </button> -->
              <button class="icon" title="Reset password" @click="requestPasswordReset(props.row.id)" :disabled="!props.row.id">
                <img src="../assets/password.svg" />
              </button>
            </span>          
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </VueGoodTable>

        </div>
        <span class="spinner relative" v-if="loading" />
      </div>

    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
    <Modal2 v-show="inviteUsersModal" @close="inviteUsersModal = false;">
      <h3 slot="header">Invite users</h3>

      <div slot="body" style="width: 336px;">        
        <label required>Role</label>
        <select class="select-css" v-model="newUsersRole">            
          <option value="Professor" selected>Professor</option>
          <option value="Admin" v-if="$store.getters.isAdmin">Admin</option>
          <option value="OrgAdmin" v-if="$store.getters.isAdmin">Organization Admin</option>
          <option value="Student">Student</option>
        </select>
        
        <label v-show="$store.getters.isAdmin">Organization</label>
        <select v-show="$store.getters.isAdmin" class="select-css" v-model="newUsersOrganizationId">
          <option :value=null selected>No Organization</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>
        
        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="false" /> <span style="vertical-align:middle;">Single user</span>
        </div>
        <ValidationProvider name="inviteEmail" :rules="{ email: true, required: true }" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Email</label>
          <input type="text" v-model.trim="newUserEmail" :class="classes" :disabled="bulkInvite" />
        </ValidationProvider>
        <div style="display:flex; gap:12px;">
          <div>
            <ValidationProvider name="inviteFirstName" :rules="{ required: true }" tag="div" v-slot="{ classes/*, errors*/ }">
              <label required>First name</label>
              <input type="text" :class="classes" v-model="newUserFirstName" :disabled="bulkInvite" />
            </ValidationProvider>
          </div>
          <div>
            <ValidationProvider name="inviteLastName" :rules="{ required: true }" tag="div" v-slot="{ classes/*, errors*/ }">
              <label required>Last name</label>
              <input type="text" :class="classes" v-model="newUserLastName" :disabled="bulkInvite" />
            </ValidationProvider>
          </div>
        </div>       

        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="true" /> <span style="vertical-align:middle;">Bulk invite</span>
        </div>
        <textarea class="newusers" :disabled="!bulkInvite" v-model="newUsers" placeholder="Paste users here, one in each line in the following CSV format: Email, FirstName, LastName (maximum 20 can be invited in one step)"></textarea>
        <div v-if="bulkInvite">          
          Found <b>{{newUsersList ? newUsersList.length : 0}}</b> emails: {{ newUsersList && (newUsersList.length == 20) ? '(maximum 20 can be invited in one step)' : '' }}
          <br>
          <span class="newuseremail" v-for="user in newUsersList" :key="user.email">
            {{user[0]}} ({{user[1] ? user[1] : '***MISSING FIRST NAME***'}}, {{user[2] ? user[2] : '***MISSING LAST NAME***'}})
          </span>
        </div>  
      </div>

      <div slot="footer">
        <button class="right" 
        @click="addUsers()"
          :disabled="bulkInvite ? (!newUsersList || newUsersList.length == 0) : invalid"
        >
          Invite {{(bulkInvite && newUsersList) ? (newUsersList.length + ' users') : ''}}
        </button>
        <button class="white right" @click="inviteUsersModal = false;">Cancel</button>
      </div>
    </Modal2>
    </ValidationObserver>

    <Modal2 v-if="showModal" @close="closeRolesModal" class="rolesmodal">      
      <h3 slot="header">Select roles</h3>
      <div slot="body">
        <div>
          <input type="checkbox" id="studentrolecheckbox" v-model="selectedRoles.student">
          <label style="display: inline-block; vertical-align:middle; margin:unset;" for="studentrolecheckbox">Student</label>
        </div>
        <div>
          <input type="checkbox" id="professorrolecheckbox" v-model="selectedRoles.professor">
          <label style="display: inline-block; vertical-align:middle; margin:unset;" for="professorrolecheckbox">Professor</label>
        </div>
        <div v-if="$store.getters.isAdmin">
          <input type="checkbox" id="adminrolecheckbox" v-model="selectedRoles.admin">
          <label style="display: inline-block; vertical-align:middle; margin:unset;" for="adminrolecheckbox">Admin</label>  
        </div>    
        <div>
          <input type="checkbox" id="orgadminrolecheckbox" v-model="selectedRoles.orgAdmin">
          <label style="display: inline-block; vertical-align:middle; margin:unset;" for="orgadminrolecheckbox">Organization Admin</label>  
        </div>   
      </div>
      <div slot="footer">
        <button @click="setRoles" :class="{disabled: loading}" :disabled="loading || (!selectedRoles.student && !selectedRoles.admin && !selectedRoles.professor && !selectedRoles.orgAdmin)">Set for selected users</button>
        <div class="formerror" v-if="error">{{error}}</div>
      </div>
    </Modal2>    

    <Modal2 v-if="showOrgModal" @close="showOrgModal = false; error = ''" class="orgmodal">      
      <h3 slot="header">Edit organization</h3>
      <div slot="body" style="width: 336px;">
        <label>Name</label>
        <input v-model.trim="tempOrg.name" type="text" placeholder="organization name"> 
        <label>Email domain</label>
        <input v-model.trim="tempOrg.emailDomain" type="text" placeholder="email domain (without the @)"> 
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="right" @click="updateOrg" :disabled="!tempOrg.name || loading">Update</button>        
      </div>
    </Modal2>  

    <Modal2 v-if="showUserOrgModal" @close="showUserOrgModal = false; error = ''" class="userorgmodal">      
      <h3 slot="header">Set organization</h3>
      <div slot="body" style="width: 336px;">
        <select class="select-css" v-model="userOrgId">
          <option value=undefined selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select> 
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="right" @click="updateUserOrg" :disabled="loading">Update</button>        
      </div>
    </Modal2>  

    <Modal2 v-if="showUserSessionsModal" @close="showUserSessionsModal = false; error = ''" class="usersessionsmodal center">      
      <h3 slot="header">
        <img src="../assets/removesessions.svg" style="width: 18px; margin-right:10px; vertical-align:middle;" />
        Assigned sessions of {{tempUser.email}}</h3>
      <div slot="body">
        <table v-if="userSessions.length">
        <tr v-for="session in userSessions" :key="session.id"> 
          <td>{{session.id}} - </td> 
          <td>{{session.name}}</td>
        </tr>
        </table>
        <span v-else>User has no sessions assigned</span>
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">        
        <button class="right" @click="unassignAllSessions" :disabled="loading || !userSessions.length">Remove from all sessions</button>        
        <button class="white right" @click="showUserSessionsModal = false;">Cancel</button>
      </div>
    </Modal2>  

    <Modal2 v-if="showResetPasswordModal" @close="showResetPasswordModal = false; error = ''" class="resetpasswordmodal center">      
      <h3 slot="header">
        <img src="../assets/password.svg" style="width: 18px; margin-right:10px; vertical-align:middle;" />
        Reset password for {{tempUser.email}}
      </h3>
      <div slot="body">
        This will generate a password reset token and send it in email for the user (it is equal as if typing the user's email in the reset password screen).
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="right" @click="resetPassword" :disabled="loading">Generate and send password reset token</button>  
        <button class="white right" @click="showResetPasswordModal = false;">Cancel</button>      
      </div>
    </Modal2> 

    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import {Roles} from '@/roles.js'
import Snackbar from '@/components/Snackbar.vue'
import Papa from 'papaparse'
//import { filter } from 'vue/types/umd';
import Modal2 from '../components/Modal2.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import { formatProgramType, formatDate, toTimeZone } from '@/utils.js'
import { saveAs } from 'file-saver'

export default {
  name: 'Users2',
  data: function(){
    return {
      orgColumns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Email domain',
          field: 'emailDomain'
        },
        {
          label: 'Actions',
          field: 'actions'
        },
      ],
      columns: [ 
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Approved',
          field: 'approved',
          filterOptions: {
            //styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'No filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            filterDropdownItems: [true, false], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
          sortable: false
        },
        {
          label: 'Confirmed',
          field: 'emailConfirmed',      
          filterOptions: {
            enabled: true, 
            placeholder: 'No filter', 
            filterDropdownItems: [true, false],
          },    
          sortable: false
        },
        {
          label: 'First Name',
          field: 'firstName',
        },
        {
          label: 'Last Name',
          field: 'lastName',
        },        
        {
          label: 'Phone',
          field: 'phone',
          sortable: false
        },
        {
          label: 'Roles',
          field: 'roles',
          // filterOptions: {
          // styleClass: 'class1', // class to be added to the parent th element
          //   enabled: true, // enable filter for this column
          //   placeholder: 'Filter This Thing', // placeholder for filter input
          //   //filterValue: 'Jane', // initial populated value for this filter
          //   filterDropdownItems: ['Admin','bbbb','ccccc'], // dropdown (with selected values) instead of text input
          //   //filterFn: this.columnFilterFn, //custom filter function that
          //   //trigger: 'enter', //only trigger on enter not on keyup 
          // }
          filterOptions: {
            enabled: true, 
            placeholder: 'No filter', 
            filterDropdownItems: ['Admin','OrgAdmin','Professor','Student'],
            filterFn: this.roleFilterFn
          },
          sortable: false
        },
        {
          label: 'ID',
          field: 'id',
          sortable: false
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        }
      ],
      //roleFilter: '',
      users: [],
      organizations: [],
      userSessions: [],
      tempUser: undefined,
      loading: false,
      error: undefined,
      searchTerm: undefined,
      orgSearchTerm: undefined,
      inviteUsersModal: false,
      bulkInvite: false,
      newUserEmail: undefined,
      newUserFirstName: undefined,
      newUserLastName: undefined,
      newUsers: '',
      showModal: false,
      showOrgModal: false,
      showUserOrgModal: false,
      showUserSessionsModal: false,
      showResetPasswordModal: false,
      selectedRoles: {
        student: false,
        professor: false,
        admin: false,
        orgAdmin: false
      },
      newUsersRole: 'Professor',
      newUsersOrganizationId: null,
      //showTechnicalUsers: false,
      newOrg: {
        name: undefined,
        emailDomain: undefined
      },
      tempOrg: {
        name: undefined,
        emailDomain: undefined
      },
      usersTab: true,
      userOrgId: undefined,
      report: {
        // year: (new Date()).getFullYear(),
        // month: (new Date()).getMonth()+1,
        from: null,
        to: null,
        binHours: 2,
        thresholdHours: 2,
        detailed: false,
        binMode: 'firstplay'
      },
      orgId: this.$store.getters.organizationId ?? -1
    }
  },
  watch:{
    orgId: async function(){
      await this.fetchUsers()
    }   
  },
  computed: {  
    // filteredUsers(){
    //   return this.roleFilter 
    //     ? this.users.filter(u => (this.showTechnicalUsers || u.lastName != "technical") && (this.roleFilter == 'provisional' ? !u.id : (u.roles && u.roles.includes(this.roleFilter)))) 
    //     : this.users.filter(u => this.showTechnicalUsers || u.lastName != "technical");
    // },
    newUsersList(){      
      return (this.bulkInvite ? Papa.parse(this.newUsers, {header: false, skipEmptyLines: true}).data : [[this.newUserEmail, this.newUserFirstName, this.newUserLastName]])
          .map(d => [d[0]?.trim()?.toLowerCase(), d[1]?.trim(), d[2]?.trim()] )
          // eslint-disable-next-line 
          .filter(d => /^[\w-\.]+[+]?[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,}$/g.test(d[0])).slice(0, 20)
    },
    selectedUnapprovedUsers(){
      return this.$refs.datatable.selectedRows.filter(u => !u.approved).map(u => u.email);
    },
    selectedPreapprovedUsers(){
      return this.$refs.datatable.selectedRows.filter(u => u.approved && !u.id).map(u => u.email);
    },
    selectedUsers(){
      return this.$refs.datatable.selectedRows;
    }
  },
  methods: { 
    formatDate(d, tz){ return formatDate(d, tz) },
    toTimeZone(d, tz){ return toTimeZone(d, tz) },
    closeRolesModal(){
      this.showModal = false; 
      this.error = '';
      this.selectedRoles.student =  this.selectedRoles.admin =  this.selectedRoles.professor = this.selectedRoles.orgAdmin = false;
    }, 
    roleFilterFn: function(data, filterString) {            
      return data && data.includes(filterString)
    },
    formatProgramType(type){
      return formatProgramType(type)
    },
    async creditLogs(id){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        let report = await axios.get(`organizations/${(id ? `${id}/` : '')}creditlogs`)
        let csv = "Organization,Date,User,Program,SessionID,AssignedDeviceCount,AssignedDeviceDetails" 
        report.data.forEach(r => {    
          console.log(JSON.parse(r.details))    
            csv += `\n${r.organization},${r.date},${r.user},${this.formatProgramType(r.program)},${r.sessionId},${r.userCount},${JSON.parse(r.details).map(d => `${d.UserId}_${d.DeviceId}_${d.DeviceName}`).join(';')}`        
        })
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `credit-logs-org-${id ? `${id}-${this.organizations.find(o => o.id == id)?.name.replace(/\s/g,'')}` : 'all'}.csv`);
      }
      catch(err){
        console.log("Error when getting credit logs: " + err)
        this.$refs.snackbar.show('Error when getting credit logs: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async creditReport(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;     
        const orgIds = this.$refs.orgdatatable.selectedRows.map(o => o.id).join(',')
        let resp = await axios.get(`organizations/creditreport${this.report.detailed ? '/details' : ''}?from=${this.report.from.toISOString()}&to=${this.report.to.toISOString()}&binHours=${this.report.binHours}&thresholdHours=${this.report.thresholdHours}&binMode=${this.report.binMode}&orgIds=${orgIds}`)
        let csv1 = Papa.unparse(this.report.detailed ? resp.data : resp.data.report)
        let blob1 = new Blob([csv1], {type: "text/plain;charset=utf-8"});        
        // const fromdate = this.toTimeZone(this.report.from, this.$store.getters.timeZone)
        // const todate = this.toTimeZone(this.report.to, this.$store.getters.timeZone)
        const fromdate = this.report.from
        const todate = this.report.to

        const fromstring =  fromdate.getUTCFullYear() + String(fromdate.getUTCMonth()+1).padStart(2,'0') + String(fromdate.getUTCDate()).padStart(2, '0') + ("0" + fromdate.getUTCHours()).slice(-2) + ("0" + fromdate.getUTCMinutes()).slice(-2) + "UTC";
        const tostring =  todate.getUTCFullYear() + String(todate.getUTCMonth()+1).padStart(2,'0') + String(todate.getUTCDate()).padStart(2, '0') + ("0" + todate.getUTCHours()).slice(-2) + ("0" + todate.getUTCMinutes()).slice(-2) + "UTC";
        saveAs(blob1, `credit-report-from-${fromstring}-to-${tostring}-${ this.report.detailed ? 'detailed' : `bin${this.report.binHours}${this.report.binMode}-threshold${this.report.thresholdHours}`}${process.env.VUE_APP_ENV != 'prod' ? ('-'+process.env.VUE_APP_ENV) : ''}.csv`);

        if(!this.report.detailed){
          let csv2 = Papa.unparse(resp.data.sessions)
          let blob2 = new Blob([csv2], {type: "text/plain;charset=utf-8"});
          saveAs(blob2, `sessions-report-from-${fromstring}-to-${tostring}-${this.$store.getters.timeZone}-${ this.report.detailed ? 'detailed' : `bin${this.report.binHours}${this.report.binMode}-threshold${this.report.thresholdHours}`}${process.env.VUE_APP_ENV != 'prod' ? ('-'+process.env.VUE_APP_ENV) : ''}.csv`);
        }
      }
      catch(err){
        console.log("Error when getting credit logs: " + err)
        this.$refs.snackbar.show('Error when getting credit logs: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async unassignAllSessionsBulk(){
      if(!confirm('Are you sure you want to bulk unassign all sessions of selected users?'))
        return
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        for (let index = 0; index < this.selectedUsers.length; index++) {
          await axios.delete(`users/${this.selectedUsers[index].id}/sessions`)            
        }    
      }
      catch(err){
        console.log("Error when bulk unassigning sessions: " + err)
        this.$refs.snackbar.show('Error when bulk unassigning sessions: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.userSessions = [] 
      }
    },
    async editRow(id){            
      let resp = await axios.get(`users/${id}/sessions`)
      this.userSessions = resp.data
      this.tempUser = this.users.find(u => u.id == id)
      this.showUserSessionsModal = true
    },     
    async unassignAllSessions(){
      await axios.delete(`users/${this.tempUser.id}/sessions`)
      this.userSessions = []
    },
    async requestPasswordReset(id){      
      this.tempUser = this.users.find(u => u.id == id)
      this.showResetPasswordModal = true
    }, 
    async resetPassword(){      
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios({ url: "auth/requestpasswordreset?email=" + encodeURIComponent(this.tempUser.email), method: "POST" }); 
        if(resp.data){
          this.$refs.snackbar.show('Password reset token successfully sent')
          this.showResetPasswordModal = false
        }
        else{
          this.error = 'Error when sending token in email. Please try again, and if the issue persists, please report it to the tech team.';
          this.$refs.snackbar.show('Error when sending token in email.')
        }
      }
      catch(err){     
        this.$refs.snackbar.show('Error when generating token: ' + err.response.data)
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data      
    },
    async addOrganization(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios.post(`organizations`, this.newOrg)
        this.organizations.push(resp.data)
        this.newOrg =  {
          name: undefined,
          emailDomain: undefined
        }
      }
      catch(err){
        console.log("Error when adding organization: " + err.response.data)
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async editOrg(id){      
      this.tempOrg = this.organizations.find(s => s.id == id)      
      this.showOrgModal = true       
    }, 
    async updateOrg(){      
      try{        
        this.error = undefined
        this.$nprogress.start()
        this.loading = true        
        let resp = await axios.post(`organizations/${this.tempOrg.id}`, this.tempOrg)
        // console.log(resp.data)      
        this.tempOrg.name = resp.data.name
        this.tempOrg.emailDomain = resp.data.emailDomain
        this.showOrgModal = false
      }
      catch(err){
        console.log("Error when updating organization: " + err.response.data);
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
    async fetchUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios.get(`users?orgId=${this.orgId}`); 
        this.users = resp.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async addUsers(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        // eslint-disable-next-line
        let resp = await axios({ url: `users?role=${this.newUsersRole}&organizationId=${this.newUsersOrganizationId ?? ''}`, data: this.newUsersList, method: "POST" }); 
        this.newUsers = '';
        this.inviteUsersModal = false;

        if(resp.data && resp.data.length){
          this.error = "Invitations created, but a problem occurred while sending for the following emails: " + resp.data.join(', ') + "\r\nCheck if the invitation exists in the Invitations tab, and to be sure please try to notify these invited user(s) separately.";
          this.$refs.snackbar.show('A problem occurred when sending out some of the invitation emails.')
        }
        else{
          this.$refs.snackbar.show('Invitation sent successfully!')
          this.newUserEmail = this.newUserFirstName = this.newUserLastName = undefined
        }

        this.inviteUsersModal = false        
        await this.fetchUsers()
      }
      catch(err){
        console.log("Error when inviting users: " + err.response);
        this.$refs.snackbar.show('An unexpected problem occurred when inviting users.')
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async activateUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;   
        this.error = undefined;    
        await axios({ url: "users/approve", data: this.selectedUnapprovedUsers, method: "POST" });
        await this.fetchUsers();        
      }
      catch(err){
        console.log("Error when approving users: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async setRoles(){
      try{
        this.$nprogress.start();
        this.loading = true;  
        this.error = undefined;

        let rolesArray = [];
        if(this.selectedRoles.student) 
          rolesArray.push(Roles.Student)
        if(this.selectedRoles.admin) 
          rolesArray.push(Roles.Admin)
        if(this.selectedRoles.orgAdmin) 
          rolesArray.push(Roles.OrgAdmin)
        if(this.selectedRoles.professor) 
          rolesArray.push(Roles.Professor)

        await axios({ url: "users/setroles", data: {
          emails: this.selectedUsers.map(e => e.email),
          roles: rolesArray
        }, method: "POST" });
        this.showModal = false;  
        this.selectedRoles.student =  this.selectedRoles.admin =  this.selectedRoles.professor = this.selectedRoles.orgAdmin = false;      
        await this.fetchUsers();        
      }
      catch(err){
        console.log("Error when applying user roles: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async updateUserOrg(){
      try{
        this.$nprogress.start();
        this.loading = true;  
        this.error = undefined;

        await axios({ url: "users/setorganization", data: {
          userIds: this.selectedUsers.map(e => e.id),
          organizationId: this.userOrgId
        }, method: "POST" });
        this.showUserOrgModal = false;
        await this.fetchUsers();        
      }
      catch(err){
        console.log("Error when updating organization for users: " + err.response?.data);
        this.error = err.response?.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async removeUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;     
        this.error = undefined;  
        await axios({ url: "users", data: this.selectedPreapprovedUsers, method: "DELETE" });
        await this.fetchUsers();        
      }
      catch(err){
        console.log("Error when removing approved users: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }
  },  
  components: {
    VueGoodTable,
    Snackbar, 
    Modal2, 
    ValidationProvider, 
    ValidationObserver
  },
  async mounted() {
    try{
      await this.fetchUsers();
      await this.getOrganizations();
    }
    finally{
      this.$store.state.showLoader = false
    }
  },
  created() {
      this.Roles = Roles;
  }
}
</script>

<style lang="scss">

.neworg{
  margin-top:15px;
  display: flex;
  input{
    flex-grow: 1;    
  }
  button{
    flex: none;
  }
}

.technicalcheckbox{
  margin-left: 20px;
  font-size: 14px;
  color: $july;
  
  input{
    vertical-align: bottom;
    margin-right: 5px;
    cursor: pointer;
  }
}

.rolesmodal{ 
  label, input[type=checkbox]{
    float: none;
    vertical-align: middle;
    cursor: pointer;
  }
  label {
    padding-left: 5px;
    &:hover{
      color: $green1;
    }
  }
}

textarea.newusers{
  margin-top: 10px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
}

span.newuseremail{
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: $green2;
  color: white;
}

.rowActions{
  display: flex;
  //opacity: 0.0;
  justify-content: space-around;
}

.vgt-table tr:hover .rowActions{
  opacity: 1;
}




main.users2{  
  display: block;
  position: relative;

  .vc-popover-content-wrapper{
    z-index: 11;
  }

  h2{
    margin: 0 0 10px 0;
    font-size: 20px;    
    font-weight: bold;
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
    }
  }

  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    td{
      padding: 5px 8px;      
      vertical-align: middle;    
    }
    th{
      white-space: nowrap;
    }
  }

  .rowActions{
    display: flex;
    align-items: center;

    button{
      width:16px;
      height: 16px;
      min-width: unset;

      img{
        width:16px;
        height: 16px;
      }
    }
  }

  .formerror{
    position: absolute;
    top: 0;
    left: 88px;
    right: 88px;
  }
}

</style>
